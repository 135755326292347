
  
  .cyber-container h2 {
    font-weight: bold;
    color: #ffffff;
    font-size: 35px;
    margin-bottom: 25px;
    margin-top: 60px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .cyber-container p {
    color: #f9f5f5;
    font-size: 22px;
    line-height: 2;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: auto;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  
  

  






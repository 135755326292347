.navbar {
    padding: 40px 0px;
    display: flex; /* Add this line to create a flex container */
    justify-content: space-between; /* Add this line to align the logo and the navigation links on either side */
}

.logo {
    align-self: center; /* Add this line to center the logo vertically */
}

.nav-link {
    color: #FFFF;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin: .4rem;
}



.nav-link:hover {
    background: var(--color-highlight);
    border-radius: 5px;
    color: var(--color-text);
}

.nav-link:last-child:hover {
    background: var(--color-highlight);
    color: var(--color-text);
}

@media (max-width: 991px) {
    .navbar-collapse,
    .navbar-nav {
        text-align: right;
    }

    .nav-link {
        margin: .3rem 0;
        padding: .5rem;
    }

    .nav-link:last-child {
        background: var(--color-highlight);
        color: var(--color-text);
        text-align: center;
    }
}

.navbar-toggler {
    border: none;
    padding-top: .8rem;
    text-align: center;
    margin-bottom: 1rem;
}


.white-text {
    color: white; /* Settext color to white */
}
.navbar-toggler-icon {
    background-image: url('../../assets/menu.png');
    background-repeat: no-repeat;
}
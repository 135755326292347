/* Webdev.css */

.about-container {
  display: flex;
  align-items: center;
  padding: 2rem;
  padding: auto;
}

.about-content {
  flex: 1; /* Take remaining space */
  margin-right: 2rem; /* Add some space between text and image */
  padding: auto;
}

.about-container h2 {
  font-size: 3.5rem;
  margin-bottom: 2rem; /* Reduced margin for better spacing */
  text-align: left;
  color: aliceblue;
  padding: auto;
}

.about-container p {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 1.6rem;
  
  margin-top: auto;

  color: aliceblue;
  padding: auto;
  
  margin-bottom: auto;
  text-align: left;
  color: aliceblue;
  line-height: 2rem;
  
}

.about-container img {
  max-width: 30rem;
  border-radius: 10px;
  margin-right: auto;
}
.about-box {
    border: 1px solid #ffffff; /* Outline color */
    padding: 2rem;
    margin: 1rem;
    border-radius: 10px; /* Adjust border radius as needed */
  }

  .about-box {
    font-size: 1.5rem;
  margin-bottom: 4rem;
  text-align: left;
  color: aliceblue;
  }


#services h1 {
  margin-top: auto;
    font-family: 'Roboto', sans-serif; /* Replace 'Roboto' with your desired Google Font */
    font-size: 36px; /* Adjust the font size as needed */
    text-align: center;
    font-weight: bold; /* Optionally, adjust the font weight */
    color: #0f4444; /* Adjust the color as needed */
    text-transform: uppercase; /* Optionally, transform the text to uppercase */
    
  }



  body {
    background-color: #01161E; /* Replace #f0f0f0 with the color of your choice */
  }

.heading-container {
    background-color: #0f4444; /* Background color for the cool box */
    border: 2px solid var(--color-dark);
    margin: 1rem;
    border: 1px solid #191A23;
    box-shadow: 0px 5px 0px #ffffff;/* Box shadow for the cool box effect */
    border-radius: 45px;
    align-items: center;
    padding: 2rem;

  }
  
  .heading-title {
    font-size: 30px;
    color: #ffffff; /* Color for the heading title */
    margin-bottom: 10px; /* Add some space between title and text */
    margin-top: auto; 
  }
  
  .heading-text {
    font-size: 20px;
    color: #ffffff; /* Color for the heading text */
  }
  
.headings {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
    margin: 4rem 0rem 2rem;
}


.headings h3 {
    background: var(--color-highlight);
    font-weight: 500;
    font-size: 40px;
    color: #2D232E;
    line-height: 41px;
    padding: .3rem;
    border-radius: 5px;
    margin-right: 1rem;
}

.headings p {
    font-weight: 400;
    font-size: 18px;
    color: #EFF6E0;
    line-height: 23px;
    max-width: 580px;
}

@media(max-width: 768px) {
    .headings {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;
      
    }

    .headings h3 {
        margin-bottom: 1rem;
        text-align: center;
        font-size: 36px;
        line-height: 46px;
    }
    
    .headings p {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
    }
}






h2 {
  font-weight: bold;
  color: #ffffff;
  font-size: 35px;
  margin-bottom: 25px;
  margin-top: 60px;
  font-family: Arial, Helvetica, sans-serif;
}

h3 {
  
  color: #c59f9f;
  font-size: 32px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 25px;
  margin-bottom: 30px;
}

.appdev-container p {
  color: #f9f5f5;
  font-size: 22px;
  line-height: 2;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: auto;
  font-family: Arial, Helvetica, sans-serif;
}
